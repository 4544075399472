<template>
    <v-container fluid class="site-width">
        <v-row>
            <v-col cols="12">
                <v-card light class="pa-5 xx" min-width="100%">
                    <v-row class="pa-5">
                        <h3>Search Results : {{ this.current_keyword }}</h3>
                    </v-row>
                    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                    <v-row class="pa-5">
                        <v-text-field label="Search terms" ref="searchfield" v-model="keywords" v-on:keyup="performSearch" filled></v-text-field>
                        <v-btn large depressed color="primary" dark @click="loadData" class="ml-5 mt-3">
                            Search<v-icon small right>fas fa-search</v-icon>
                        </v-btn>
                    </v-row>
                    <v-row class="pa-5 full-width d-flex justify-space-between">
                        <v-col cols="12" sm="8" class="px-3 flex-shrink-1">
                            <v-list two-line v-if="pagedResults.length > 0 && !loading && !search_dialog">
                                <v-list-item class="search-result" href="#" v-for="(result, k) in pagedResults" :key="k">
                                    <v-list-item-content @click="loadPage(result)">
                                        <v-list-item-title><span class="q-subtitle">{{ getCategoryName(result) }}:</span> <strong class="q_blue_1--text">{{ getTitle(result) }}</strong></v-list-item-title>
                                        <v-list-item-subtitle>{{ getDescription(result) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon small v-if="result.external_url && result.external_url != 'internal'">fas fa-external-link-alt</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <div v-else-if="!loading && !search_dialog" class="text-center">Sorry, no results!</div>
                            <v-pagination v-if="pagedResults.length > 0 && !loading && !search_dialog" v-model="page" :length="total_pages" :total-visible="7" color="q_blue_1"></v-pagination>
                            <hr class="ma-4" />
                        </v-col>
                        <v-col cols="12" sm="4" outlined class="text-center px-3 pt-3 pb-6 " color="">
                            <v-card outlined class="px-5 pb-5 pt-3">
                                <div class="my-5 pt-1" style="border-radius:50%; background-color:#0083eb; width:40px; height:40px; margin:0 auto; ">
                                    <v-icon x-large color="white"> icon-q-support</v-icon>
                                </div>
                                <h5>Didn't find what you were looking for?</h5>
                                <v-btn small depressed color="q_blue_1" dark to="/support#step3" class="ml-5 mt-3">
                                    Submit a support ticket
                                </v-btn>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import SearchResult from '@/store/Models/SearchResult'
export default {
    data() {
        return {
            search: '',
            itemsPerPage: 10,
            keywords: null,
            page: 1,
            pagedResults: [],
            current_keyword: null,
            all_results: [],
            returned_results: {
                pages: {
                    data: [],
                    meta: {}
                },
                agents: {
                    data: [],
                    meta: {}
                },
            }
        }
    },
    mounted: function() {
        this.keywords = this.$route.query.keyword
        if (this.keywords != null && this.keywords != '') {
            this.loadData();
        }
    },
    computed: {
        'total_pages': function() {
            return Math.ceil(this.all_results.length / this.itemsPerPage);
        },
        /*'all_results': function() {
            if (this.search_dialog) {
                return []
            }
            return SearchResult.all()
        },*/
        'loading': function() {
            return this.$store.state.entities.search_result.loading
        },
        'search_dialog': function() {
            return this.$store.state.show_search;
        }
    },
    methods: {
        loadPage: function(result) {
            if (typeof result.AgentCode != 'undefined') {
                this.$router.push('/agents/' + result.AgentCode);
            }
            else if (typeof result.ViewLink != 'undefined') {
                window.open(result.ViewLink, '_blank');
            }
            else if (result.external_url && result.external_url != 'internal') {
                window.open(
                    result.external_url,
                    '_blank' // <- This is what makes it open in a new window.
                );
            } else {
                this.$router.push('/page/' + result.slug);
            }
            this.search = false;
        },
        loadData: debounce(function() {
            this.page = 1;
            this.current_keyword = this.keywords;
            QuilityAPI.getSearchResults(null, { search: this.keywords, itemsPerPage: 'all' })
                .then(json => {
                    this.returned_results = json;
                    this.all_results = [].concat(json.agents.data, json.pages.data, json.docs.data);
                    this.updatePagedResults(1);
                    this.trackSearchResults();
                });
        }, 500),
        trackSearchResults: function() {
            gtag('event', 'search', {
                'event_category': 'SearchResultsPage',
                'event_label': this.keywords
            });
        },
        performSearch: function(e) {
            if (e.keyCode == 13) {
                this.loadData();
            }
        },
        searchResults: function() {
            this.active = false;
            this.$store.commit('updateShowSearch', false);
            this.$router.push({ name: 'SearchResults', query: { keyword: this.keywords } }).catch(err => { g.showError("Whoops! " + err) })
        },
        updatePagedResults(page) {
            var first = page * this.itemsPerPage - this.itemsPerPage;
            var last = this.itemsPerPage * page;
            this.pagedResults = this.all_results.slice(first, last)
        },
        getCategoryName: function(result) {
            if (typeof result.LeadershipLevel != 'undefined') {
                return "Agent";
            }
            else if (typeof result.ViewLink != 'undefined') {
                return "Document";
            }
            else {
                return result.category_name
            }
        },
        getTitle: function(result) {
            if (typeof result.AgentName != 'undefined') {
                return result.AgentName
            }
            else if (typeof result.ViewLink != 'undefined') {
                return result.DocTitle;
            }
            else {
                return result.title
            }
        },
        getDescription: function(result) {
            if (typeof result.LeadershipLevel != 'undefined') {
                return result.LeadershipLevel
            }
            else if (typeof result.ViewLink != 'undefined') {
                return result.DocDesc;
            }
            else {
                return result.description
            }
        }
    },
    watch: {
        'page': function(newV) {
            this.updatePagedResults(newV)
        },
        '$route.query.keyword': function(newV) {
            this.keywords = newV
            if (this.keywords != null && this.keywords != '') {
                this.loadData();
            }
        }
    },
    components: {

    }
}

</script>
<style>
</style>
